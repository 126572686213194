<template>
  <div class="header-wrap">
    <header class="header">
      <div class="header-inner">
        <div class="left">
          <div class="form-wrap" v-if="showSearch">
            <div class="search-form">
              <input
                autocomplete="off"
                type="text"
                placeholder="请输入你要搜索的关键词"
                class="input"
                @click.stop=""
                @keyup.enter="submit"
                v-model="keyword"
                autofocus
              />
              <span class="search-button" @click="submit"> </span>
            </div>
          </div>
        </div>
        <div class="right">
          <van-button
            v-if="!userinfo"
            @click="showLogin"
            type="primary"
            size="small"
            >登录</van-button
          >
          <div v-else class="userinfo" @click="showInfoMask">
            <span v-if="userinfo">用户:{{ userinfo.email }}</span>
            <template v-if="userinfo.expired">
              <p v-if="userinfo.expiredate" class="danger">
                会员有效期已于：{{
                  getDiffDays(userinfo.expiredate) * -1
                }}天前过期
              </p>
              <p v-else class="danger">你尚未成为会员</p>
            </template>
            <p v-else class="success">
              会员有效期剩余:{{ getDiffDays(userinfo.expiredate) }}天
            </p>
          </div>
        </div>
      </div>
    </header>
    <van-dialog
      class="info-dialog"
      v-model="infomask"
      :before-close="closeMask"
      :show-confirm-button="false"
    >
      <div class="content" v-if="userinfo">
        <p class="uid" v-if="userinfo.email">邮箱账号:{{ userinfo.email }}</p>
        <div class="vipinfo">
          <div class="section">
            <div class="info-line">
              <div class="left">
                <template v-if="userinfo.expired">
                  <p v-if="userinfo.expiredate" class="danger">
                    会员有效期：{{ userinfo.expiredate | formatDate }} 已过期
                  </p>
                  <p v-else class="danger">你尚未成为会员</p>
                </template>
                <p v-else class="success">
                  会员有效期：{{ userinfo.expiredate | formatDate }}
                </p>
              </div>
              <!-- <van-button size="mini" round type="primary" @click="upgrade">{{
                !userinfo.expiredate ? "立刻捐赠并成为会员" : "立即续费"
              }}</van-button> -->
            </div>
          </div>
        </div>
        <p class="logout tip">
          <a href="javascript:void(0);" @click="logout">清除Cookie退出</a>
        </p>
      </div>
      <van-icon class="close" name="cross" @click="infomask = false" />
    </van-dialog>
  </div>
</template>
<script>
import { logout } from "@/api/user";
import moment from "moment";
import { Dialog } from "vant";
import { refundOrder } from "@/api/pay";
import copyToClipboard from "../utils/clipboard";

const getDiffDays = (date) => {
  return moment(date).diff(moment(), "days");
};
export default {
  props: {
    showSearch: {
      default: true,
    },
  },
  data() {
    return {
      keyword: "",
      isFrame: false,
      infomask: false,
    };
  },
  mounted() {
    if (window.self != window.top) {
      this.isFrame = true;
    }
    setTimeout(() => {
      if (this.$route.query.keyword) this.keyword = this.$route.query.keyword;
    }, 10);
  },
  methods: {
    getDiffDays,
    submit() {
      if (this.isFrame) {
        return this.openWindow(
          `${window.location.origin}/#/main/search?keyword=${encodeURIComponent(
            this.keyword
          )}`,
          true
        );
      }
      this.$store.commit("changeFilterSearchType", -1);
      return (window.location.href = `${
        window.location.origin
      }/#/main/search?keyword=${encodeURIComponent(this.keyword)}`);
    },
    async logout() {
      const res = await logout();
      if (res.code == 0) {
        this.$toast.success("退出成功");
        window.location.reload();
      } else {
        return this.$toast.fail("稍后重试");
      }
    },
    showBindDialog() {
      this.showBindUserDialog();
      this.infomask = false;
    },
    async showLogin() {
      this.showLoginDialog();
    },
    closeMask(_, done) {
      this.infomask = false;
      done();
    },
    async showInfoMask() {
      Dialog.confirm({
        title: "账号退出",
        message: "是否退出当前账号?",
      })
        .then(() => {
          this.logout();
        })
        .catch(() => {});
      // await this.updateUserinfo()
      // this.infomask = true
    },
    upgrade() {
      this.showPayDialog();
      this.infomask = false;
    },
    refund(tradeno) {
      refundOrder({ order_id: tradeno }).then(() => {
        this.$toast.success("退款成功，将在1小时内到账");
        this.updateUserinfo();
      });
    },
    copyInviteLink() {
      copyToClipboard(`${this.DOMAIN}/#/?code=${this.userinfo.invitecode}`);
      this.$toast("复制成功");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_base.scss";
@import "@/assets/scss/_var.scss";
.header-wrap {
  height: 61px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 0;
  background-color: #fff;
  z-index: 10;
  .header-inner {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    max-width: 100%;
    min-height: 36px;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
    }
    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .form-wrap {
      .search-form {
        position: relative;
        display: inline-block;
        .input {
          width: 320px;
          font-size: 13px;
          border-radius: 6px;
          height: 36px;
          padding-left: 10px;
          padding-right: 50px;
          outline: none;
          border: 1px solid #ddd;
          background-color: #f8f8f8;
          transition: all ease 0.3s;
          &:focus {
            border-color: $theme-color;
          }
        }
        .search-button {
          display: inline-block;
          position: absolute;
          bottom: 5px;
          width: 28px;
          right: 5px;
          top: 5px;
          z-index: 2;
          outline: none;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: all ease 0.2s;
          background: url("../assets/images/search.png") no-repeat center center;
          background-size: 14px 14px;
        }
      }
    }

    .van-button {
      padding: 0 10px;
    }
    .userinfo {
      color: #666;
      font-size: 12px;
      text-align: right;
      cursor: pointer;
    }

    .avatar {
      cursor: pointer;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #ddd;
    }
  }
}

.van-dialog {
  width: 380px;
  overflow: visible;
  .content {
    text-align: center;
    padding: 20px;
  }
  .tip {
    font-size: 13px;
    color: #666;
    &.logout {
      margin-top: 20px;
    }
    a {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  &.info-dialog {
    .uid {
      font-size: 12px;
      margin-top: 10px;
    }
    .section {
      margin-top: 12px;
      padding: 12px 10px;
      border-radius: 4px;
      background-color: rgba($theme-color, 0.1);
    }
    .vipinfo {
      .info-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        text-align: left;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .small {
        margin-top: 5px;
      }
      .small,
      .tip {
        font-size: 12px;
        color: #666;
        font-weight: normal;
        &.bold {
          font-weight: bold;
        }
      }
      .left {
        font-weight: bold;
      }
      .em {
        margin-bottom: 5px;
      }
      .van-button {
        padding: 0 10px;
        flex: none;
        margin-left: 10px;
      }
    }
  }
  .record-info {
    .record-header {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .record-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      .left {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        .label {
          padding-right: 6px;
        }
      }
      .van-button {
        padding: 0 10px;
        flex: none;
      }
    }
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    bottom: -46px;
    left: 50%;
    margin-left: -18px;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    background-color: rgba(#000, 0.7);
    &:hover {
      cursor: pointer;
      background-color: #000;
    }
  }
}

@media (max-width: 700px) {
  .header-wrap {
    height: 57px;
  }
  .header {
    padding: 10px;
    .header-inner {
      .form-wrap {
        .search-form {
          input {
            width: 240px !important;
          }
        }
      }
    }
  }
}
</style>
